<template>
  <div>
    <CardItemContainer 
      class="earndir" 
      title="Ways to earn" 
      :items="earnWays.map(res => ({...res, styles: setThemeStyle()}))"
    >
      <template v-slot:default='slotProps'>
        <Badge :style="{'backgroundColor':slotProps.item.styles.color, 'color':slotProps.item.styles.fontColor}">
          {{ '+' + slotProps.item.value }}
        </Badge>
      </template>
    </CardItemContainer>
  </div>
</template>

<script>
import { hexToRgb } from '../util';
import CardItemContainer from '../components/CardItemContainer.vue';
import Badge from '../components/Badge.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  mounted() {
    this.$api.earnways().then((res) => {
      this.queryEarnWays(res);
      this.spinLoading(false);
    });
  },
  components: {
    CardItemContainer,
    Badge,
  },
  computed: {
    ...mapGetters({
      earnWays: "earnWays",
      themeColor: "themeColor",
      fontColor: "fontColor"
    }),
  },
  methods: {
    ...mapActions(["queryEarnWays", "spinLoading"]),
    wrapClick() {
      this.isClick = true;
    },
    convertBackgroundColor(color) {
      const rgb = hexToRgb(color);
      return `rgba(${rgb.r} ${rgb.g} ${rgb.b} /0.1)`;
    },
    setThemeStyle() {
      return {
        color:this.themeColor,
        fontColor: this.fontColor,
        backgroundColor: this.convertBackgroundColor(this.themeColor)
      }
    }
  },
  
};
</script>
<style scoped>
.earndir {
  min-height: 445px;
}
.earndir ::v-deep(.container-list) {
  background-color: #fff !important;
  border-bottom: none;
}
.earndir ::v-deep(.container-center .tit) {
  color: #0E0300FF;
  font-size: 15px;
  width: 140px;
}
.earndir ::v-deep(.container-center .sub-tit) {
  color: #B7B7B7FF;
  font-size: 11px;
  width: 140px;
}
.earndir ::v-deep(.badge-container) {
  width: 80px;
  font-size: 15px;
  height: 30px;
}
.earndir ::v-deep(.container-right .badge-wrap .badge-container .clicked) {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  content: '';
  background-color: red;
  z-index: 99999;
}
</style>
